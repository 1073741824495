import React, { useMemo } from "react";
import { Collapse, Modal } from "antd";
import "./catalog.scss";
import { NavLink } from "react-router-dom";
import { LockOutlined } from "@ant-design/icons";
import PopModal from "../../../../components/modal/modal";

function Catalog(data) {
  if (data) {
    const { Panel } = Collapse;
    const number = (num) => {
      if (num < 10) {
        return <span style={{ marginRight: "10px" }}>0{num}</span>;
      } else {
        return <span style={{ marginRight: "10px" }}>{num}</span>;
      }
    };
    let cnt = 0;
    const catalog = data.zhuanlan.modules.map((content, mindex) => {
      if(content.id !== 142 && content.id !== 143 && content.id !== 157){
        const chapterlist = content.chapters.map((content, cindex) => {
          if (content.id) {
            cnt++;
            return (
                <div key={cindex}>
                  {content.content ? (
                      <NavLink
                          className="zhuanlans-detail-catalog-chapters-navlink"
                          exact
                          to={"/chapters/" + content.id}
                          activeClassName="active"
                      >
                        <div className="zhuanlans-detail-catalog-chapters">
                          {number(cnt)}
                          {content.name}
                        </div>
                      </NavLink>
                  ) : (
                      <div
                          className="zhuanlans-detail-catalog-chapters"
                          onClick={() => {
                            Modal.confirm({
                              title: "付费内容解锁",
                              content: (
                                  <div style={{ position: "relative", left: "-20px" }}>
                                    <div>
                                      {data.zhuanlan.purchasable === 0 && (
                                          <div
                                              style={{
                                                position: "absolute",
                                                right: "-10px",
                                                top: "-25px",
                                                fontSize: "12px",
                                                color: "orange",
                                              }}
                                          >
                                            注：该专栏只能合集购买
                                          </div>
                                      )}
                                      <img
                                          style={{ width: "100%" }}
                                          src={data.zhuanlan.image}
                                      />
                                      <div
                                          style={{
                                            textAlign: "center",
                                            fontSize: "20px",
                                          }}
                                      >
                                        {data.zhuanlan.name}
                                      </div>
                                      <div
                                          style={{
                                            textAlign: "center",
                                            color: "darkgray",
                                            marginTop: "10px",
                                          }}
                                      >
                                        该文章为付费内容，请登录并购买后浏览
                                      </div>
                                    </div>
                                  </div>
                              ),
                              okType: "primary",
                              okText: <a href={data.purchase_link}>购买</a>,
                              cancelText: "取消",
                            });
                          }}
                      >
                        <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                        >
                          <div style={{ marginRight: "10px" }}>
                            {number(cnt)}
                            {content.name}
                          </div>
                          <div>
                            <LockOutlined />
                          </div>
                        </div>
                      </div>
                  )}
                </div>
            );
          }
        });
        return (
            <Panel
                className="zhuanlans-detail-catalog-modules"
                header={content.name}
                key={mindex}
            >
              {chapterlist}
            </Panel>
        );
      }
    });
    return (
      <div className="zhuanlans-catalog-wrapper">
        <Collapse
          expandIconPosition={"right"}
          bordered={false}
          defaultActiveKey={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
          className="zhuanlans-detail-catalog"
        >
          {catalog}
        </Collapse>
        {(data.zhuanlan.id === 29) && <div style={{textAlign:'center',color:'lightgrey'}}>由于公司流片，停更2周，11.24恢复更新</div>}
        {(data.zhuanlan.id === 36) && <div style={{textAlign:'center',color:'lightgrey'}}>作者本周要准备重要会议，停更1周，11.24恢复更新</div>}
        {(data.zhuanlan.id === 38) && <div style={{textAlign:'center',color:'lightgrey'}}>作者年假期间疯狂更新，本周与下周周更4篇起，预计或早于11月底更新完毕</div>}
      </div>
    );
  }
}

export default Catalog;
